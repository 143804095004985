<template>
  <div class="accountFContainer">
    <a-form-model :model="accountForm" labelAlign="left" ref="accountForm" :rules="rules" v-bind="layout">
      <a-spin :spinning="spinning">
        <a-form-model-item label="公司名称" prop="accountName">
          <a-input v-model.trim="accountForm.accountName" disabled />
        </a-form-model-item>
        <a-form-model-item label="纳税人识别号" prop="taxNumber">
          <a-input placeholder="请输入纳税人识别号" v-model.trim="accountForm.taxNumber" />
        </a-form-model-item>
        <a-form-model-item label="地址" prop="registerAddress">
          <a-input placeholder="请输入地址" v-model.trim="accountForm.registerAddress" />
        </a-form-model-item>
        <a-form-model-item label="电话" prop="taxPhone">
          <a-input placeholder="请输入电话" v-model.trim="accountForm.taxPhone" />
        </a-form-model-item>
        <a-form-model-item label="开户行" prop="accountBank">
          <a-input placeholder="请输入开户行" v-model.trim="accountForm.accountBank" />
        </a-form-model-item>
        <a-form-model-item label="银行账号" prop="accountCode">
          <a-input placeholder="请输入银行账号" v-model.trim="accountForm.accountCode" />
        </a-form-model-item>
        <a-form-model-item label="开票类型" prop="invoiceType">
          <a-radio-group v-model="accountForm.invoiceType" @change="callback">
            <a-radio-button value="1"> 增值税专用发票 </a-radio-button>
            <a-radio-button value="2"> 增值税普通发票 </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-button type="primary" class="primary" @click="handleSubmit">修改资质</a-button>
      </a-spin>
    </a-form-model>
  </div>
</template>
<script>
import { updateTaxInfo, taxInfoList } from '@/api/media'
export default {
  name: 'AccountForm',
  data () {
    return {
      accountForm: {},
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      rules: {
        accountName: [{ required: true, message: '', trigger: 'blur' }],
        taxNumber: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        registerAddress: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        taxPhone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        accountBank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        accountCode: [{ required: true, message: '请输入银行账号', trigger: 'blur' }],
        invoiceType: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      spinning: false
    }
  },
  created () {
    // 获取资质发票信息
    this.getTaxInfoList()
  },
  methods: {
    callback (e) {
      this.accountForm.invoiceType = e.target.value
    },
    // 获取资质发票信息
    async getTaxInfoList () {
      this.spinning = true
      const res = await taxInfoList()
      if (res.code === 200 && res.data) {
        const { accountName, taxNumber, registerAddress, taxPhone, accountBank, accountCode, invoiceType } =
          res.data[0] || {}
        this.accountForm = {
          registerAddress,
          accountName,
          taxNumber,
          taxPhone,
          accountBank,
          accountCode,
          invoiceType: invoiceType || '1'
        }
        this.spinning = false
      }
    },
    // 修改资质发票信息
    async updateTaxInfos (data) {
      const res = await updateTaxInfo(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.getTaxInfoList()
      }
    },
    handleSubmit () {
      this.$refs.accountForm.validate((validate) => {
        if (!validate) return false
        this.updateTaxInfos(this.accountForm)
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.accountFContainer {
  padding: 20px;
  min-height: 80vh;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .primary {
    background: #409eff;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    margin-right: 80px;
    float: right;
  }
}
.aRadioBtn();
::v-deep {
  .ant-form {
    width: 60%;
    .ant-form-item-label-left {
      text-align: right;
    }
  }
  .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked {
    border: 1px solid #317ced;
  }
}
</style>
